import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
// import { About, Gallery, Home, ServiceDetails, Services } from './screens';
import { ColorRing } from "react-loader-spinner";
import  './utils/styles.css'

const Home = React.lazy(() => import("./screens/Home/Home"));
const About = React.lazy(() => import("./screens/About/About"));
const Gallery = React.lazy(() => import("./screens/Gallery/Gallery"));
const Services = React.lazy(() => import("./screens/Services/Services"));
const ServiceDetails = React.lazy(() =>
  import("./screens/ServiceDetails/ServiceDetails")
);

const App = () => {
  const Spinner = () => {
    return (
      <div className="spinner-container">
        {/* Customize your spinner here */}
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  };

  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        {/* <Menu /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<ServiceDetails />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default App;
